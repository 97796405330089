<script lang="ts" setup>
const isGuest = useIsGuest();
const { seoData, homePageData, jackpotGamesArrayable, banners, bannerEnabled } = useHomePage({
	immediate: true
});

useHead({
	title: () => seoData.value?.seoTitle || "NoLimitCoins",
	meta: [
		{
			hid: "description",
			name: "description",
			content: () => seoData.value?.seoDescription
		},
		{
			name: "keywords",
			content: () => seoData.value?.seoKeywords || ""
		}
	]
});

const { isSweepStakes } = useSwitchMode();
const isCatalogGames = useState<boolean>("show-catalog-games", () => false);
const showBanners = computed(() => bannerEnabled.value && banners.value?.length && banners.value.length > 1);

onMounted(() => {
	isCatalogGames.value = false;
});
</script>

<template>
	<NuxtLayout>
		<LazyOHomeBanners v-if="showBanners" class="section-item banner" :banners="banners" />
		<div v-else class="section-item full-mob">
			<LazyOHomeQuickCash v-if="!isGuest" />
			<LazyOHomeSignUp v-if="isGuest" />
		</div>

		<LazyOGamesCatalog :show-catalog="isCatalogGames" />
		<template v-if="!isCatalogGames">
			<LazyOHomeSectionGames :itemSection="0" />
			<OLazyComponentNew id="home-promotions" class="section-item">
				<LazyOHomePromotions />
			</OLazyComponentNew>
			<LazyOHomeSectionGames :itemSection="1" hideOnLeave />
			<OLazyComponentNew id="latest-winners" class="section-item">
				<LazyOHomeLatestWinners />
			</OLazyComponentNew>
			<LazyOHomeSectionGames :itemSection="2" hideOnLeave />
			<OLazyComponentNew id="home-jackpot" class="section-item">
				<OHomeJackpot :jackpot="homePageData?.jackpots" :jackpot-games="jackpotGamesArrayable" />
			</OLazyComponentNew>
			<LazyOHomeSectionGames :itemSection="3" hideOnLeave />
			<OLazyComponentNew id="home-collections" hideOnLeave class="section-item">
				<OHomeCollections />
			</OLazyComponentNew>
			<LazyOHomeSectionGames :itemSection="4" hideOnLeave />
			<OLazyComponentNew v-if="isGuest" id="home-ratings" class="section-item">
				<OHomeRatings
					:jackpots="homePageData?.jackpots"
					:isSweepStakes="isSweepStakes"
					:last-big-win="homePageData?.infoPlate?.lastBigWinTournamentPoints"
				/>
			</OLazyComponentNew>
			<LazyOHomeSectionGames :itemSection="5" hideOnLeave />
			<OLazyComponentNew v-if="showBanners" id="home-banners" class="section-item full-mob section-auth">
				<OHomeSignUp v-if="isGuest" />
				<OHomeQuickCash v-else />
			</OLazyComponentNew>
			<LazyOHomeSectionGames :itemSection="6" hideOnLeave />
		</template>
	</NuxtLayout>
</template>

<style lang="scss" scoped>
.section-item {
	max-width: 1428px;
	padding: 0 16px;
	margin: 42px auto;
	@include media-breakpoint-up(md) {
		padding: 0 27px;
		margin: 34px auto;
	}
	&.banners,
	&.section-auth {
		margin: 0 auto;
	}
	@include media-breakpoint-down(md) {
		&.full-mob {
			padding: 0;

			&:first-child {
				margin-bottom: 12px;
			}
		}
	}
}
</style>
